import "../addvertiseSection/addvertiseBanner2.scss"

const AddvertiseBanner2 = () => {
  return (
    <section>
        <div className="container">
          <div className="addvertiseImg2">
            <img src="images\addvertiseImg2.svg" alt="addvertiseBanner2" />
          </div>
        </div>
    </section>
  )
}

export default AddvertiseBanner2;