import '../../rightSide/MatchStatRightPart';

const MatchNotes = () => {
  return (
    <section>
        <div className="match-details-card">
          <h3 className="match-details-header">Match Notes</h3>
          <div className="match-notes">
            <p className="detail-value">Match started at 25 Sep, 01:07 PM.</p>
          </div>
          <div className="match-notes">
            <p className="detail-value">
              Untda Dhani: 53 runs in 5.2 overs, Extras 5
            </p>
          </div>
          <div className="match-notes">
            <p className="detail-value">
              Innings Break: Untda Dhani - 81/6 in 8 overs (Arbaaz Murad 17,
              Sajid 9)
            </p>
          </div>
          <div className="match-notes">
            <p className="detail-value">Innings Ended at 25 Sep, 01:34 PM</p>
          </div>
        </div>
    </section>
  )
}

export default MatchNotes