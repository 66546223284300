import '../../../matchStatSection/leftSide/MatchStatLeftPart';
    
    const ScoreSummary = () => {
      return (
        <section>
            <div className="score-summary">
        <div className="last-bat">
          <strong>Last Bat:</strong> Praveen 6 (10b) • FOW: 16/2 (4.1 Ov)
        </div>
        <div className="balls-container">
          <span className="ball red">W</span>
          <span className="ball red">W</span>
          <span className="ball grey">1</span>
          <span className="ball green">4</span>
          <span className="ball purple">6</span>
          <span className="ball">13th</span>
          <span className="ball grey">1</span>
          <span className="ball green">4</span>
          <span className="ball grey">1</span>
          <span className="ball grey">1</span>
          <span className="ball grey">1</span>
          <span className="ball grey">1</span>
          <span className="ball">13th</span>
          <span className="ball grey">1</span>
          <span className="ball green">4</span>
          <span className="ball grey">1</span>
          <span className="ball grey">1</span>
          <span className="ball grey">1</span>
          <span className="ball grey">1</span>
        </div>
      </div>
        </section>
      )
    }
    
    export default ScoreSummary