import "../addvertiseSection/addvertiseBanner.scss";

const AddvertiseBanner = () => {
  return (
    <section>
      <div className="container">       
          <div className="addvertiseImg">
            <img src="images\addvertiseImg.svg" alt="addvertiseBanner" />
          </div>
        </div>   
    </section>
  );
};

export default AddvertiseBanner;
