const data = [
    {
        'id': 1,
        'title': 'Team One Site Vartaknagar Won Ranjeet Chashak – 2016',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared',
        'city': 'Chennai'
    },
    {
        'id': 2,
        'title': 'Ganesh XI Mahul Won Rishaan Champions Trophy 2016',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared',
        'city': 'Mumbai'
    },
    {
        'id': 3,
        'title': 'Team Navapada Won Nilje Makarsankrant Trophy 2016',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg  ',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared',
        'city': 'Kolkata'
    },
    {
        'id': 4,
        'title': 'Aditya Sports Padgha Team Won Padgha Gramin Samrat Chashak',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared',
        'city': 'Srinagar'
    },
    {
        'id': 5,
        'title': 'Ganesh XI Mahul Won Rishaan Champions Trophy 2016',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared',
        'city': 'Bangalore'
    },
    {
        'id': 6,
        'title': 'Team Navapada Won Nilje Makarsankrant Trophy 2016',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared',
        'city': 'Hyderabad'
    },
    {
        'id': 7,
        'title': 'Ganesh XI Mahul Won Rishaan Champions Trophy 2016',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared',
        'city': 'Mumbai'
    },
    {
        'id': 8,
        'title': 'Team One Site Vartaknagar Won Ranjeet Chashak – 2016',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared',
        'city': 'Chennai'
    },
    {
    'id': 9,
        'title': 'Team Navapada Won Nilje Makarsankrant Trophy 2016',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg  ',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared',
        'city': 'Kolkata'
    },
    {
        'id': 10,
        'title': 'Team Navapada Won Nilje Makarsankrant Trophy 2016',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg  ',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared',
        'city': 'Srinagar'
    },
    {
        'id': 11,
        'title': 'Team Navapada Won Nilje Makarsankrant Trophy 2016',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg  ',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared',
        'city': 'Bangalore'
    },
    {
        'id': 12,
        'title': 'Team One Site Vartaknagar Won Ranjeet Chashak – 2016',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared',
        'city': 'Chennai'
    },
    {
        'id': 13,
        'title': 'Team One Site Vartaknagar Won Ranjeet Chashak – 2016',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared',
        'city': 'Chennai'
    },
    {
        'id': 14,
        'title': 'Team One Site Vartaknagar Won Ranjeet Chashak – 2016',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared',
        'city': 'Chennai'
    },
    {
        'id': 15,
        'title': 'Ganesh XI Mahul Won Rishaan Champions Trophy 2016',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared',
        'city': 'Mumbai'
    },
    {
        'id': 16,
        'title': 'Ganesh XI Mahul Won Rishaan Champions Trophy 2016',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared',
        'city': 'Mumbai'
    },
    {
        'id': 17,
        'title': 'Ganesh XI Mahul Won Rishaan Champions Trophy 2016',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared',
        'city': 'Mumbai'
    },
    {
        'id': 18,
        'title': 'Team Navapada Won Nilje Makarsankrant Trophy 2016',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg  ',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared',
        'city': 'Kolkata'
    },
    {
        'id': 19,
        'title': 'Team Navapada Won Nilje Makarsankrant Trophy 2016',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg  ',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared',
        'city': 'Kolkata'
    },
    {
        'id': 20,
        'title': 'Team Navapada Won Nilje Makarsankrant Trophy 2016',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg  ',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared',
        'city': 'Kolkata'
    },
    {
        'id': 21,
        'title': 'Aditya Sports Padgha Team Won Padgha Gramin Samrat Chashak',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared',
        'city': 'Srinagar'
    },
    {
        'id': 22,
        'title': 'Aditya Sports Padgha Team Won Padgha Gramin Samrat Chashak',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared',
        'city': 'Srinagar'
    },
    {
        'id': 23,
        'title': 'Aditya Sports Padgha Team Won Padgha Gramin Samrat Chashak',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared',
        'city': 'Srinagar'
    },
    {
        'id': 24,
        'title': 'Team Navapada Won Nilje Makarsankrant Trophy 2016',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared',
        'city': 'Hyderabad'
    },
    {
        'id': 25,
        'title': 'Team Navapada Won Nilje Makarsankrant Trophy 2016',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared',
        'city': 'Hyderabad'
    },
    {
        'id': 26,
        'title': 'Team Navapada Won Nilje Makarsankrant Trophy 2016',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared',
        'city': 'Hyderabad'
    },
    {
        'id': 27,
        'title': 'Team Navapada Won Nilje Makarsankrant Trophy 2016',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared',
        'city': 'Hyderabad'
    },
]

export default data;