export const states = [
  { id: "1", name: "Maharashtra" },
  { id: "2", name: "Andhra Pradesh" },
  { id: "3", name: "Uttar Pradesh" },
  { id: "4", name: "Telangana" },
  { id: "5", name: "Rajasthan" },
  { id: "6", name: "Assam" }
];

export const cities = {
  Maharashtra: [
    { id: "1", name: "Mumbai" },
    { id: "2", name: "Pune" },
    { id: "3", name: "Nagpur" }
  ],
  "Andhra Pradesh": [
    { id: "1", name: "Visakhapatnam" },
    { id: "2", name: "Vijayawada" },
    { id: "3", name: "Tirupati" }
  ],
  "Uttar Pradesh": [
    { id: "1", name: "Lucknow" },
    { id: "2", name: "Kanpur" },
    { id: "3", name: "Agra" }
  ],
  Telangana: [
    { id: "1", name: "Hyderabad" },
    { id: "2", name: "Warangal" },
    { id: "3", name: "Khammam" }
  ],
  Rajasthan: [
    { id: "1", name: "Jaipur" },
    { id: "2", name: "Udaipur" },
    { id: "3", name: "Jodhpur" }
  ],
  Assam: [
    { id: "1", name: "Guwahati" },
    { id: "2", name: "Dibrugarh" },
    { id: "3", name: "Silchar" }
  ]
};

export const zones = {
  Mumbai: [
    { id: "1", name: "Zone 1" },
    { id: "2", name: "Zone 2" }
  ],
  Pune: [
    { id: "1", name: "Zone A" },
    { id: "2", name: "Zone B" }
  ],
  Visakhapatnam: [
    { id: "1", name: "Zone X" },
    { id: "2", name: "Zone Y" }
  ],
  Lucknow: [
    { id: "1", name: "Zone Alpha" },
    { id: "2", name: "Zone Beta" }
  ],
  // Add other cities and their corresponding zones here
};
