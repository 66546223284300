import '../../rightSide/MatchStatRightPart';

const ImageContainer = () => {
  return (
    <section>
        <div className="image-container">
          <img src="images\imageStat1.png" alt="image-stat1" />
        </div>
    </section>
  )
}

export default ImageContainer